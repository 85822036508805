<template>
  <div>
    <v-autocomplete
      v-model="selectedMember"
      :loading="loading"
      :items="members"
      :label="label"
      :search-input.sync="search"
      dense
      hide-no-data
      chips
      class="mx-4"
      flat
      color="blue-grey lighten-2"
      return-object
      @change="onChange"
      :filter="filterItems"
      :auto-select-first="true"
      :hint="$t('memberLookupHint')"
    >
      <template v-slot:no-data>
        <v-list-item>
          <v-list-item-title>
            {{$t('memberLookupHint')}}
          </v-list-item-title>
        </v-list-item>
      </template>
      <template v-slot:selection="data">
        <v-chip
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="remove(data.item)"
          class="pa-4"
        >
          {{ data.item.name }}({{ data.item.userCode }}) / {{ data.item.userName }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title>{{ data.item.name }}({{ data.item.userCode}})</v-list-item-title>
            <v-list-item-subtitle>{{ data.item.userName }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
    <div class="text-left">
      <v-chip
      v-for="(chip, index) in selected" :key="index"
      class="ma-2"
      close
      @click:close="remove(chip)"
    >
      {{ chip.name }}({{ chip.userCode }}) / {{ chip.userName }}
    </v-chip>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { memberApi } from '@/services/api'

export default {
  props: {
    label: {
      type: String,
      default: 'Select Member',
    },
    value: { type: Array, default: () => [] }
  },
  data() {
    return {
      isUpdating: false,
      loading: false,
      search: '',
      members: [],
      selectedMember: {},
      selected: []
    }
  },
  watch: {
      value(newVal) {
        this.selectedMember = {...newVal}
      },
      search (val) {
        console.log('search changed', val)

        val &&  val.length >= 3 &&  this.fetchMembers(val)
      },
      select(val){
        this.$emit('input', val)
      }
  },
  mounted() {
    this.selectedMembers = {...this.value}
    this.fetchMembers(this.search)
  },
  methods: {
    fetchMembers: _.debounce(async function() {
      const search = this.search
      console.log('fetchMembers', search)
      if(!search || !search.length > 2){
        return
      }

      this.isUpdating = true
      this.loading = true

      const response = await memberApi.lookupAll(search)
        console.log('fetch member response', response)

        this.members = response.data.map((m) => ({
          id: m.id,
          name: m.name,
          email: m.email,
          userName: m.userName,
          userCode: m.userCode,
        }))

        console.log('mapped members', this.members)

        this.loading = false
        this.isUpdating = false

    }, 1000),
    filterItems (item, queryText) {
        const textOne = item.name.toLowerCase()
        const textTwo = item.email.toLowerCase()
        const searchText = queryText.toLowerCase()

        return textOne.indexOf(searchText) > -1 ||
          textTwo.indexOf(searchText) > -1
      },
    onChange() {
      console.log('selected member', this.selectedMember)

      if(!this.selected.find((m) => m.id == this.selectedMember.id)){
        this.selected = [...this.selected, ...[this.selectedMember]]
        this.$emit('input', this.selected)
      }

      this.selectedMember = {}
    },
    remove(item){
      const idx = this.selected.findIndex((m) => m.id == item.id)
      this.selected.splice(idx, 1)
      this.$emit('input', this.selected)
    }
  }
};
</script>
