import apiClient from '@/services/api/clients/api-client.js'
import configService from '@/services/config.service'

const path = `${configService.config.apiUrl}/member`
const client = apiClient.build(path)

//ToDo: user a real cache service
const cache = {}

client.fetchCached = id => {
  if (cache[id]) {
    console.log('member cache hit')
    return Promise.resolve(cache[id])
  }

  //Item is already being fetched
  if (cache[`_${id}`]) {
    console.log('member cache pending')
    return Promise.resolve(cache[`_${id}`])
  }

  console.log('member cache miss')

  const p = client.fetch(id)
  cache[`_${id}`] = p.then(
    member => {
      cache[id] = member
      return member
    },
    () => {
      return {}
    }
  )

  return p
}

client.lookup = term => {
  const url = `${path}/lookup`
  return client.__api.post(url, {term})
}

client.lookupAll = term => {
  const url = `${path}/lookup/all`
  return client.__api.post(url, {term})
}

export default client
