import Create from '@/views/teams/create'
import Detail from '@/views/teams/detail'
import SelectTeam from '@/views/teams/select'
import TeamEdit from '@/views/teams/edit'
import TeamMembers from '@/views/teams/members'

export default [
 
  // {
  //   path: '/teams',
  //   name: 'teams-index',
  //   component: Teams,
  //   meta: {
  //     authenticated: true
  //   }
  // },
  {
    path: '/teams/new',
    name: 'team-create',
    component: Create,
    meta: {
      authenticated: true
    }
  },  
  {
    path: '/teams/:id',
    name: 'team-detail',
    component: Detail,
    meta: {
      authenticated: true
    }
  }, 
  {
    path: '/teams/:id/edit',
    name: 'team-edit',
    component: TeamEdit,
    meta: {
      authenticated: true
    }
  }, 
  {
    path: '/teams/:id/members',
    name: 'team-members',
    component: TeamMembers,
    meta: {
      authenticated: true
    }
  },
  {
    path: '/teams/select/:id',
    name: 'team-select',
    component: SelectTeam,
    meta: {
      authenticated: true
    }
  }
]
