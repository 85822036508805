<template>
<div>
    <app-toolbar :crumbs="crumbs" />
    <div v-if="model">
        <team-form v-model="model" @cancel="onCancel" @save="onSave" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import teamsApi from '@/services/api/teams'
import teamForm from '@/components/teams/edit-form'

export default {
    components: {
        appToolbar,
        teamForm
    },
    data(){
        return{
            model:null,
        }
    },
    computed: {
        ...mapGetters('messages', ['errors']),
        ...mapGetters('user', ['teams']),
        id(){
            return this.$route.params.id
        },
        crumbs() {
            const crumbs = [
                { text: this.$t('teams'), disabled: true }
            ]

            if (this.model) {
                crumbs.push({
                    text: this.model.name,
                    to: { name: 'team-detail' },
                    params: { id: this.model.id }
                })
            }
            crumbs.push({ text: this.$t('edit'), disabled: true })

            return crumbs
        },
    },
    mounted(){
        this.load()
    },
    methods:{
        ...mapActions('user', ['setTenant']),
        async load(){
            const response = await teamsApi.fetch(this.id)
            this.model = response
        },
        onCancel() {
            this.$router.go(-1)
        },
        async onSave(model) {            
            

            this.$router.push({
                name: 'team-detail',
                params: { id: model.id }
            })
        }
    },
    
}
</script>