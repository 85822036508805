<template>
<div>
  <div v-if="model">
      <app-toolbar :crumbs="crumbs">
          <v-spacer></v-spacer>
          <v-btn
              v-if="isTeamAdmin && !model.isArchived && !model.isDeleted"
              plain
              :to="{ name: 'team-edit', params: { id: model.id } }"
              >
              {{ $t('edit') }}
          </v-btn>

          <v-icon v-if="!model.isPublic">mdi-lock</v-icon>
      </app-toolbar>
      <v-divider class="ma-2"></v-divider>

      <v-row>
          <v-col>
              <v-card flat width="100%">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div>
                      {{ $t('organization') }}:
                      {{ model.organization.name }}
                    </div>
                    <div class="mt-2" v-if="model.description">
                      <flns-text-area
                        label=""
                        :text="model.description"
                        :border="false"
                      />
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
          </v-col>
      </v-row>
      <div>
          <v-divider class="ma-2"></v-divider>
          <div class="d-flex justify-space-between">
              <h3>{{ $t('members') }}</h3>
              <v-spacer></v-spacer>
              <v-btn
                  v-if="isTeamAdmin"
                  plain
                  :to="{ name: 'team-members', params: { id: model.id } }"
              >
                  {{ $t('teamMemberAdd') }}
              </v-btn>

          </div>
          <div class="pt-2">
              <v-data-table
                  :headers="memberHeaders"
                  :items="model.members"
                  :disable-pagination="true"
                  sort-by="name"
                  item-key="id"
                  hide-default-footer
              >
                  <template v-slot:item.actions="{ item }" class="text-right">
                      <team-member-menu v-if="isTeamAdmin" :team="model" :member="item" @remove="removeMember" @update="updateMember"/>
                  </template>
              </v-data-table>
          </div>
      </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import teamApi from '@/services/api/teams'
import teamMemberMenu from '@/components/teams/team-member-menu'

export default {
    components: {
        appToolbar,
        teamMemberMenu
    },
    data(){
        return{
            model:null,
            memberHeaders: [
                { text: 'Name', value: 'name', sortable:true },
                { text: 'User Code', value: 'userCode' },
                { text: 'Email', value: 'email' },
                { text: 'Team Owner', value: 'isTeamOwner' },
                { text: 'Team Admin', value: 'isTeamAdmin' },
                { text: '', value: 'actions', align: 'right'}
            ]
        }
    },
    computed: {
        ...mapGetters('user', ['teams', 'isTeamAdmin']),
        id(){
            return this.$route.params.id
        },
        crumbs() {
            const items = [
                { text: this.$t('teams'), disabled:true}
            ]

            if (this.model) {
                items.push({
                text: this.model.name,
                disabled: true
                })
            }

            return items
        },
    },
    mounted(){
        this.load(this.id)
    },
    watch: {
        id(newValue){
             this.load(newValue)
        }
    },
    methods:{
        ...mapActions('user', ['setTenant']),
        async load(id){
            const response = await teamApi.fetch(id)
            this.model = response
        },
        async removeMember(member){
            this.model.members = this.model.members.filter(x => x.userId != member.userId)
        },
        async updateMember(member){
            this.model.members = [...this.model.members.filter(x => x.userId != member.userId), member]
        }
    }
}
</script>
