<template>
  <div>
    <div class="d-flex justify-space-between mb-2">
      <div>
        <h2>{{ $t('collections') }}</h2>
      </div>
      <v-card tile flat>
        <v-btn plain :to="{ name: 'collection-create' }">
          {{ $t('create') }}
        </v-btn>
        <v-btn icon :to="{ name: 'packages-index' }" :title="$t('packageUploads')">
          <v-icon>mdi-package-up</v-icon>
        </v-btn>
      </v-card>
    </div>

    <div class="d-flex justify-start w-full">
      <v-card tile flat :width="$vuetify.breakpoint.mdAndDown ? '100%' : '50%'">
        <v-form @submit.prevent="filter">
          <v-text-field
            class="my-3"
            v-model="filterTerm"
            :label="$t('filter')"
            clearable
            clear-icon="mdi-close mdi-sm"
            color="default"
            hide-details="true"
            full-width
            dense
            solo
            @click:clear="clear"
          >
            <template v-slot:prepend-inner>
              <v-icon :title="$t('searchesCommon')" small>
                mdi-text-search
              </v-icon>
            </template>
            <template v-slot:append>
              <v-badge
                v-model="hasFilters"
                overlap
                bordered
                :content="filterCount"
              >
                <v-icon
                  :title="$t('advancedFiltersBuild')"
                  @click="showFilters = !showFilters"
                >
                  mdi-filter-variant
                </v-icon>
              </v-badge>
            </template>
          </v-text-field>
          <div v-if="showFilters">
            <v-checkbox
              class="mt-0"
              hide-details="true"
              v-model="advancedFilters"
              :label="$t('collectionsIncludeArchived')"
              value="1"
              @click="includeArchived = !includeArchived"
            ></v-checkbox>
            <v-btn primary @click="filter" class="my-3">
              {{ $t('search') }}
            </v-btn>
          </div>
        </v-form>
      </v-card>
    </div>

    <v-toolbar flat outlined dense>
      <v-toolbar-items>
        <span class="px-5"></span>
        <v-btn
          :disabled="!canMerge"
          text
          primary
          @click="merge"
          :title="$t('collectionsMerge')"
        >
          <v-icon color="primary" left>mdi-merge</v-icon>
          {{ $t('merge') }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
      v-model="selectedRows"
      :headers="headers"
      :items="items"
      :options.sync="tableOptions"
      :server-items-length="serverItemsLength"
      class="elevation-1"
      item-key="id"
      :loading="isLoading"
      loading-text="Loading...."
      show-select
      @click:row="view"
    >
      <template v-slot:item.shortId="{ item }">
        <div class="text-no-wrap">{{ item.shortId }}</div>
      </template>

      <template v-slot:item.eventDateTime="{ item }">
        {{ item.eventDateTime | dateFormat }}
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="text-right text-no-wrap">
          <v-icon
            small
            class="mr-2"
            @click="view(item)"
            :title="$t('collectionView')"
            >mdi-square-edit-outline</v-icon
          >
          {{ item.numberOfMaterial }}
          <v-icon small class="mr-2" :title="$t('material')"
            >mdi-label-multiple</v-icon
          >
          {{ item.numberOfPeople }}
          <v-icon small :title="$t('people')">mdi-account</v-icon>
          {{ item.numberOfDrawings || 0 }}
          <v-icon small :title="$t('drawing')">mdi-drawing</v-icon>

          <span v-if="item.numberOfUnreadMessages > 0">
            {{ item.numberOfUnreadMessages || 0 }}
            <v-icon small :title="$t('unreadMessages')" color="red"
              >mdi-message</v-icon
            >
          </span>
          <!-- <span v-if="enableCollectionTypes">
            <v-chip
              x-small
              chip
              class="ma-1"
              :title="$t('collectionType')"
              :color="collectionColorMap[item.collectionType || 'Other']"
              />
          </span> -->
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { PageSize } from '@/constants'
import { getCollectionTypes } from '@/services/utils'

export default {
  data() {
    return {
      advancedFilters: [],
      showFilters: false,
      filterTerm: '',
      includeArchived: false,
      footerProps: {
        showItemsPerPage: false
      },
      selectedRows: [],
      isLoading: true,
      tableOptions: {
        page: 1,
        itemsPerPage: PageSize,
        sortBy: ['createdDateTime'],
        sortDesc: [true]
      },
      searchOptions: {
        includeArchived: false,
        pageSize: PageSize
      }
    }
  },
  watch: {
    tableOptions: {
      handler(val) {
        this.searchOptions = {
          ...this.searchOptions,
          ...{
            page: val.page,
            orderBy: val.sortBy[0],
            isAscending: !val.sortDesc[0]
          }
        }
        this.filter()
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('user', ['hasFeature']),
    ...mapGetters('collections', {
      items: 'results',
      meta: 'meta',
      term: 'term'
    }),
    enableCollectionTypes() {
      return this.hasFeature('collectionTypes') ==  true
    },
    collectionTypes() {
      return getCollectionTypes()
    },
    collectionColorMap() {
      return this.collectionTypes.reduce((acc, type) => {
        acc[type.value] = type.color
        return acc
      }, {})
    },
    headers() {
      const headers =  [
        { text: this.$t('id'), value: 'shortId', width: '75px' },
        // { text: this.$t('number'), value: 'collectionNumber' },
        { text: this.$t('name'), value: 'name' },
        { text: this.$t('date'), value: 'eventDateTime' },
        { text: this.$t('createdBy'), value: 'createdByUser.name' },
        { text: '', value: 'actions', sortable: false }
      ]

      if(this.enableCollectionTypes) {
        headers.splice(3, 0, { text: this.$t('collectionType'), value: 'collectionType' })
        //headers.push({ text: this.$t('collectionType'), value: 'collectionType' })
      }

      return headers
    },
    paging() {
      if (this.meta) {
        return this.meta.paging
      }
      return {}
    },
    serverItemsLength() {
      return this.meta?.paging?.totalItems
    },
    hasFilters() {
      return this.filterCount > 0
    },
    filterCount() {
      return this.advancedFilters.reduce(function(a, b) {
        return parseInt(a) + parseInt(b)
      }, 0)
    },
    hasRowSelected() {
      return this.selectedRows.length > 0
    },
    canMerge() {
      return this.selectedRows.length > 1
    },
    numberLabel() {
      return this.$t('number')
    }
  },
  async mounted() {
    if (this.$route.query.term) {
      this.filterTerm = this.$route.query.term
      await this.filter()
    } else {
      await this.all({...this.searchOptions})
      this.filterTerm = this.term
    }

    this.isLoading = false
  },
  methods: {
    ...mapActions('collections', ['all', 'search', 'setTerm']),
    buildFilter() {
      alert('Build advanced filter')
    },
    async clear() {
      this.selectedRows = []

      if (this.term) {
        const options = {}
        if (this.advancedFilters) {
          options.includeArchived = this.includeArchived
        }
        await this.all(options)
        this.setTerm({ term: this.filterTerm })
      }
    },
    async filter() {
      this.searchOptions.term = this.filterTerm
      this.selectedRows = []

      if (this.advancedFilters) {
        this.searchOptions.includeArchived = this.includeArchived
      }

      this.setTerm(this.searchOptions)

      console.log('searchOptions', this.searchOptions)

      if (this.searchOptions.term) {
        await this.search(this.searchOptions)
      } else {
        await this.all(this.searchOptions)
      }
    },
    view(item) {
      this.$router.push({
        name: 'collection-detail',
        params: { collectionId: item.id }
      })
    },
    merge() {
      if (this.canMerge) {
        this.$router.push({
          name: 'collections-merge',
          query: { ids: this.selectedRows.map(x => x.id) }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-data-table tbody tr:hover {
  cursor: pointer;
}

::v-deep .v-data-footer__select {
  display: none;
}
</style>
