export const ACCEPTED_FILE_TYPES = 'image/*,application/pdf,video/mp4,audio/*'

export const PageSize = 50

export const ROLES = {
    SYSADMIN: 'sysadmin',
    ORGADMIN: 'orgadmin',
    TEAM_ADMIN: 'team-admin',
    TEAM_OWNER: 'team-owner'
}
