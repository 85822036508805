<template>
    <div>{{  $t('loading') }}....</div>
</template>
<script>
    export default{
        mounted(){
            const to = this.$route.query.to ?? '/';
            if(to){
                this.$router.push(to)
            }
        }
    }
</script>