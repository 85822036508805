<script>
import { mapGetters } from 'vuex'
import { ROLES } from '@/constants'

export default {
  computed: {
    ...mapGetters('user', [
      'organization',
      'user',
      'isInRole',
      'isAuthenticated',
      'userId',
      'tenantId'
    ]),
    isOrgAdmin() {
      return this.isInRole(ROLES.ORGADMIN)
    },
    isSysAdmin() {
      return this.isInRole(ROLES.SYSADMIN)
    },
    profileLink() {
      return '/profile'
    }
  }
}
</script>
