<template>
    <div>
        <app-toolbar :crumbs="crumbs" />

        <v-card flat tile v-if="team">
            <v-card-text>

                    <v-row no-gutters>
                        <v-col cols="12">
                            <member-lookup v-model="model" label="Member"></member-lookup>
                        </v-col>
                    </v-row>
                <v-form ref="form" v-model="valid">
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-checkbox
                                v-model="model.isTeamAdmin"
                                :label="$t('isTeamAdmin')"
                            ></v-checkbox>
                        </v-col>
                        <v-col cols="12">
                            <v-checkbox
                                v-model="model.isTeamOwner"
                                :label="$t('isTeamOwner')"
                                @change="isTeamOwnerChanged"
                            ></v-checkbox>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn plain color="default" @click="onCancel">
                    {{ $t('cancel') }}
                </v-btn>
                <v-btn tile large color="primary" @click="onSave" :disabled="!valid">
                    {{ $t('save') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import appToolbar from '@/components/layout/app-toolbar'
import memberLookup from '@/components/member/lookup-single.vue'
import teamsApi from '@/services/api/teams'

export default {
    components: {
        appToolbar,
        memberLookup
    },
    data() {
        return {
            model: {},
            team: null,
            valid: false,
        }
    },
    computed: {
        ...mapGetters('messages', ['errors']),
        ...mapGetters('user', ['teams']),
        id() {
            return this.$route.params.id
        },
        crumbs() {
            const crumbs = [
                { text: this.$t('teams'), disabled:true }
            ]

            if (this.team) {
                crumbs.push({
                    text: this.team.name,
                    to: { name: 'team-detail' },
                    params: { id: this.team.id }
                })
            }
            crumbs.push({ text: this.$t('members'), disabled: true })

            return crumbs
        },
    },
    mounted() {
        this.load()
    },
    methods: {
        async load() {
            const response = await teamsApi.fetch(this.id)
            this.team = response
        },
        isTeamOwnerChanged(e){
            console.log('isTeamOwnerChanged', e)
            if(this.model.isTeamOwner){
                this.model.isTeamAdmin = true
            }
        },
        onCancel() {
            this.$router.go(-1)
        },
        async onSave() {
            //todo: save members
            console.log('save', this.model)

            if(this.model.isTeamOwner){
                await teamsApi.addTeamOwner(this.id, this.model.id)
            }
            else if(this.model.isTeamAdmin){
                await teamsApi.addTeamAdmin(this.id, this.model.id)
            }
            else{
                await teamsApi.addMember(this.id, this.model.id)
            }

            this.$router.push({
                name: 'team-detail',
                params: { id: this.team.id }
            })
        }
    },

}
</script>
