<template>
    <div>
        <app-toolbar :crumbs="crumbs" />
        <team-form v-model="model" @save="save" @cancel="cancel" />
    </div>
</template>

<script>
import appToolbar from '@/components/layout/app-toolbar'
import teamForm from '@/components/teams/form'

import { mapActions, mapGetters} from 'vuex'
export default{
    components: {
        appToolbar,
        teamForm
    },
    data(){
        return {
            model: {}
        }
    },
    computed:{
        ...mapGetters('user', ['user', 'tenant']),
        crumbs() {
      return [
        { text: this.$t('teams'), disabled: true },
        { text: this.$t('create'), disabled: true }
      ]
    }
    },
    methods:{
        ...mapActions('user', ['setTenant', 'addTeam']),
        cancel(){
            this.model = {}
            this.$router.go(-1)
        },
        async save(entity){
            const team = {
              id: entity.id,
              name: entity.name,
              isPublic: entity.isPublic,
              isTeamAdmin: true,
              isTeamOwner: true,
              organization: {...this.user.organizations.find(o => o.id === entity.organizationId)},
              roles: ['team-admin', 'team-owner'],
              tenantId: entity.tenantId,
              tenantType: 'Team'
            }

            await this.addTeam(team)
            await this.setTenant(team)

            this.$router.push({name: 'team-detail', params: { id: team.id}})
        }
    }
}
</script>
