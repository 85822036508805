<template>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon 
            size="small" 
            v-bind="attrs"
            v-on="on"
        >
            <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <v-list width="250" dense>
        <v-list-item v-if="!member.isTeamOwner" @click="addTeamOwner">
          <v-list-item-title>{{ $t('teamAddOwner') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!member.isTeamAdmin" @click="addTeamAdmin">
          <v-list-item-title>{{ $t('teamAddAdmin') }}</v-list-item-title>
        </v-list-item>        
        <v-list-item v-if="member.isTeamOwner" @click="removeTeamOwner">
          <v-list-item-title>{{ $t('teamRemoveOwner') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="member.isTeamAdmin && !member.isTeamOwner" @click="removeTeamAdmin">
          <v-list-item-title>{{ $t('teamRemoveAdmin') }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click="removeMember">
          <v-list-item-title>{{ $t('teamRemoveMember') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
import teamsApi from '@/services/api/teams'

export default{
    props: {
        team: { type:Object, required: true},
        member: { type: Object, required: true}
    },
    methods: {
       async removeMember(){
          await teamsApi.removeMember(this.team.id, this.member.userId)
          this.$emit('remove', this.member)
       }, 
       async removeTeamAdmin(){
          await teamsApi.removeTeamAdmin(this.team.id, this.member.userId)
          this.$emit('update', {...this.member, ...{isTeamAdmin: false, isTeamOwner: false}})
       },
       async removeTeamOwner(){
          await teamsApi.removeTeamOwner(this.team.id, this.member.userId)
          this.$emit('update', {...this.member, ...{isTeamAdmin: false, isTeamOwner: false}})
       },
       async addTeamAdmin(){
          await teamsApi.addTeamAdmin(this.team.id, this.member.userId)
          this.$emit('update', {...this.member, ...{isTeamAdmin: true, isTeamOwner: false}})
       },
       async addTeamOwner(){
          await teamsApi.addTeamOwner(this.team.id, this.member.userId)
          this.$emit('update', {...this.member, ...{isTeamAdmin: true, isTeamOwner: true}})
       }
    }
}
</script>