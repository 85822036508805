import apiClient from '@/services/api/clients/api-client.js'
import configService from '@/services/config.service'

const path = `${configService.config.apiUrl}/teams`

const client = apiClient.build(path)

client.addMember = (id, userId) => {
    const memberPath = `${path}/${id}/members`
    return client.__api.post(memberPath, {'userId': userId}).then(response => response.data)
}

client.removeMember = (id, userId) => {
    const memberPath = `${path}/${id}/members/${userId}`
    return client.__api.delete(memberPath).then(response => response.data)
}

client.addTeamAdmin = (id, userId) => {
    const memberPath = `${path}/${id}/admins`
    return client.__api.post(memberPath, {'userId': userId}).then(response => response.data)
}

client.removeTeamAdmin = (id, userId) => {
    const memberPath = `${path}/${id}/admins/${userId}`
    return client.__api.delete(memberPath).then(response => response.data)
}

client.addTeamOwner = (id, userId) => {
    const memberPath = `${path}/${id}/owners`
    return client.__api.post(memberPath, {'userId': userId}).then(response => response.data)
}

client.removeTeamOwner = (id, userId) => {
    const memberPath = `${path}/${id}/owners/${userId}`
    return client.__api.delete(memberPath).then(response => response.data)
}
export default client
