import _ from 'lodash'
import AuthService from '@/services/auth.service.js'
import locationApi from '@/services/location'
import i18n, { selectedLocale } from '@/plugins/i18n'
import UserProfileService from '@/services/api/profile'
import { ROLES } from '@/constants'
import messageHub from '@/messaging/hub'

const loadTenant = () => {
  try{
    if(window.localStorage)
    {
      const storedValue = window.localStorage.getItem('tenant')
      if(storedValue){
        return JSON.parse(storedValue)
      }
      window.localStorage ? JSON.parse(window.localStorage.getItem('tenant') || '{}') : null
    }
    return null
  }
  catch{
    return null
  }
}

export default {
  namespaced: true,
  state: {
    user: null,
    locale: selectedLocale,
    settings: {},
    tenant: loadTenant(),
    tokenUser: null
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
    setLocale(state, payload) {
      state.locale = payload
    },
    setLocation(state, payload) {
      state.location = payload
    },
    setSettings(state, payload) {
      state.settings = payload
    },
    setTenant(state, payload) {
      state.tenant = payload
      if(window.localStorage){
        window.localStorage.setItem('tenant', JSON.stringify(payload))
      }
      console.log('setting user store tenant', payload)
      messageHub.connectToTenant(payload?.tenantId)
    },
    setTokenUser(state, payload) {
      state.tokenUser = payload
    },
    disable2fa(state) {
      state.user.isTwoFactorEnabled = false
    },
    enable2fa(state) {
      state.user.isTwoFactorEnabled = true
    },
    addTeam(state, payload){
      state.user.teams.push(payload)
    },
    updateTeam(state, payload){

      const idx = state.user.teams.findIndex(t => t.id = payload.id)

      if(idx!== -1){
        console.log('committing team update')
        state.user.teams[idx] = {...payload}
      }
    }
  },
  actions: {
    async setUser({ commit, state }) {
      const user = await AuthService.getUser()

      commit('setTokenUser', user)

      if(user && !user.expired){
        let profile = user.profile

        if(!profile.user){
          profile = await UserProfileService.fetchUserProfile(user.access_token)
        }

        commit('setUser', profile.user)

        if(state.tenant == null || profile.user.memberships.indexOf(state.tenant.tenantId) != -1){
          //fallback to default tenant if tenant is null or previous tenant is not in the list of memberships
          commit('setTenant', profile.tenant)
        }

        const settings = await UserProfileService.fetchSettings(user.access_token)
        commit('setSettings', settings)
      }
      else{
        commit('setUser', null)
      }
    },
    async setTenant({ commit }, payload) {
      commit('setTenant', payload)
    },
    async clearUser({ commit }) {
      commit('setTenant', null)
      commit('setUser',  null)
    },
    async setLocation({ commit }, payload) {
      if (payload) {
        commit('setLocation', payload)
        return
      }

      const browserLocation = await locationApi.browserLocation()
      if (browserLocation) {
        const { latitude, longitude } = browserLocation
        commit('setLocation', { latitude, longitude })
      } else {
        commit('setLocation', locationApi.defaultLocation())
      }
    },
    async setLocale({ commit }, locale) {
      i18n.locale = locale
      commit('setLocale', { locale: locale })
    },
    async disable2fa({ commit }) {
      commit('disable2fa')
    },
    async enable2fa({ commit }) {
      commit('enable2fa', true)
    },
    async logout({ commit }) {
      commit('setUser', null)
      commit('setTenant', null)
      commit('setTokenUser', null)
      await AuthService.logout()
    },
    async addTeam({commit, state}, payload){
      if(payload){
        const existing = state.user.teams.find(x => x.id == payload.id)

        if(!existing){
          console.log('adding team mutation', payload)
          commit('addTeam', payload)
        }
        else{
          console.log('adding team update mutation')
          commit('updateTeam', payload)
        }
      }
    },
    async updateTeam({commit, state}, payload){
      if(payload){
        commit('updateTeam', payload)
        if(payload.id == state.tenant.id){
          commit('setTenant', payload)
        }
      }
    }
  },
  getters: {
    tenant: state => {
      return state.tenant || {}
    },
    user : state => {
      return state.user || {}
    },
    organization: state => {
      if (state.tenant) {
        return state.tenant
      }
      return {}
    },
    allRoles: state => {
      const roles = []

      if (state.tenant && state.tenant.roles){
        roles.push(...state.tenant.roles)

        if(state.tenant.organization){
          roles.push(...state.tenant.organization.roles)
        }
      }

      if(state.user.homeOrganization){
        roles.push(...state.user.homeOrganization.roles)
      }

      if(state.user.isSysAdmin){
        roles.push(ROLES.SYSADMIN)
      }
      return _.uniq(roles)
    },
    isInRole: (state, getters) => role => {
      return getters.allRoles.indexOf(role) > -1
    },
    isInTeamRole: (state) => role => {
      return state.tenant.roles.indexOf(role) > -1
    },
    isTeamAdmin(state) {
      return state.tenant.isTeamAdmin
    },
    isTeamOwner(state){
      return state.tenant.isTeamOwner
    },
    isTeamSelected(state){
      return state.tenant.tenantType == 'Team'
    },
    isAuthenticated: state => {
      return (
        state.user !== null &&
        !state.expired
      )
    },
    isReady: (state, getters) => {
      return getters.isAuthenticated && getters.tenantId !== null
    },
    userCode: state => {
      if (state.user) {
        return state.user?.userCode
      }
      return ''
    },
    userId: state => {
      if (state.user) {
        return state.user?.id
      }
      return ''
    },
    organizations: state => {
      if(state.user){
        return state.user.organizations
      }
    },

    teams: state => {
      if(state.user){
        return state.user.teams
      }
    },
    tenantId: state => {
      if (state.tenant) {
        return state.tenant?.tenantId
      }
    },
    location: state => {
      return state.location
    },
    locale: state => {
      if (state.locale) {
        return state.locale
      }

      if (state.user && state.user['locale']) {
        return state.user['locale']
      }

      return 'en'
    },
    expiresAt: state => {
      if(state.tokenUser?.expires_at){
        return new Date(state.tokenUser?.expires_at * 1000)
      }
      return null
    },
    hasMultipleTenants: state => {
      return state.user?.memberships?.length > 1
    },
    applicationName: state => {
      return state.settings?.applicationName
    },
    logoUrl: state => {
      return state.settings?.logoUrl
    },
    faviconUrl: state => {
      return state.settings?.faviconUrl
    },
    hasFeature: state => feature => {
      return state.settings?.features?.includes(feature)
    }
  }
}
